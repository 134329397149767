import { Button, CircularProgress, Grid, Typography } from '@mui/material';
import { FC, useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import toast from 'react-hot-toast';
import { useMutation } from '@tanstack/react-query';

import theme from '../styles/theme';
import ControlledDesktopDatePickerInput from 'ui/components/inputs/controlled-desktop-date-picker-input';
import { GenerateReportForm } from '../utils/interfaces/generateReportForm';
import { GenerateReportSchema } from '../utils/validations/generateReportSchema';
import { generateReport } from '../http/api/coupon';
import downloadBlob from '../utils/downloadBlob';

const ReportView: FC = () => {
  const {
    control,
    formState: { errors, dirtyFields, isValid },
    handleSubmit,
    watch,
  } = useForm<GenerateReportForm>({
    mode: 'all',
    resolver: yupResolver(GenerateReportSchema),
  });

  const startDate = watch('startDate');
  const endDate = watch('endDate');

  const startMaxDate = useMemo(() => {
    return endDate || new Date();
  }, [endDate]);

  const endMinDate = useMemo(() => {
    return startDate || new Date();
  }, [startDate]);

  const generateReportMutation = useMutation({
    mutationFn: generateReport,
  });

  const onGenerateReport = useCallback(
    async (data: GenerateReportForm) => {
      const parameters = {
        startDate: data.startDate.toISOString(),
        endDate: data.endDate.toISOString(),
      };

      try {
        const file = await generateReportMutation.mutateAsync(parameters);
        if (file) {
          downloadBlob(file);
          toast.success('Su reporte ha sido generado');
        }
        // eslint-disable-next-line no-empty
      } catch (_error) {}
    },
    [generateReportMutation]
  );

  const submitButtonContent = () => {
    return generateReportMutation.isPending ? (
      <CircularProgress size={25} color="inherit" />
    ) : (
      'Generar'
    );
  };

  return (
    <form onSubmit={handleSubmit(onGenerateReport)}>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Typography
            variant="h5"
            color={theme.palette.text.primary}
            sx={{ fontWeight: 600 }}
          >
            Generación de informe
          </Typography>
        </Grid>
        <Grid item>
          <ControlledDesktopDatePickerInput
            label="Fecha inicial"
            name="startDate"
            control={control}
            displayFormat="dd/MM/yyyy"
            errors={errors.startDate}
            isDirty={Boolean(dirtyFields?.startDate)}
            inputsProps={{
              maxDate: startMaxDate,
            }}
          />
        </Grid>
        <Grid item>
          <ControlledDesktopDatePickerInput
            label="Fecha final"
            name="endDate"
            control={control}
            displayFormat="dd/MM/yyyy"
            errors={errors.endDate}
            isDirty={Boolean(dirtyFields?.endDate)}
            inputsProps={{
              minDate: endMinDate,
              maxDate: new Date(),
            }}
          />
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            type="submit"
            disabled={!isValid || generateReportMutation.isPending}
            fullWidth
            sx={{
              padding: theme.spacing(1),
            }}
          >
            {submitButtonContent()}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default ReportView;
