import * as yup from 'yup';

export const searchByCouponSchema = yup.object({
  couponId: yup
    .string()
    .typeError('Digite un cupón válido')
    .matches(/^\d+$/, 'Solo números')
    .test('len', 'Digite un cupón válido', (value) => {
      const strValue = value ? value.toString() : '';
      return strValue.length >= 7;
    })
    .required('Es requerido'),
});
