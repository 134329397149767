import { ICoupon } from 'recaudo-movil-common';
import api from '../api';

export const searchCoupon = async (couponId: number) => {
  return api
    .get<ICoupon | null>(`/coupon?couponId=${couponId}`)
    .then((r) => r.data);
};

export const payCoupon = async (couponId: number) => {
  return api.post(`/coupon/${couponId}`).then((r) => r.data);
};

export const generateReport = async ({
  startDate,
  endDate,
}: {
  startDate: string;
  endDate: string;
}) => {
  return api
    .get<Blob>(`/coupon/report?startDate=${startDate}&endDate=${endDate}`, {
      responseType: 'blob',
    })
    .then((r) => r.data);
};
