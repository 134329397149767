import { FC, useCallback, useState } from 'react';
import { Button, CircularProgress, Grid, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import theme from '../styles/theme';
import ControlledTextInput from 'ui/components/inputs/controlled-text-input';
import { CouponStatusEnum, ICoupon } from 'recaudo-movil-common';
import { SearchByCouponForm } from '../utils/interfaces/searchByCouponForm';
import { searchByCouponSchema } from '../utils/validations/searchByCouponSchema';
import PaymentCoupon from '../components/PaymentCoupon';
import { useMutation } from '@tanstack/react-query';
import { searchCoupon } from '../http/api/coupon';

const SearchView: FC = () => {
  const [couponInfo, setCouponInfo] = useState<ICoupon | null>(null);

  const {
    control,
    formState: { errors, dirtyFields, isValid },
    handleSubmit,
    reset,
  } = useForm<SearchByCouponForm>({
    defaultValues: { couponId: '' },
    mode: 'all',
    resolver: yupResolver(searchByCouponSchema),
  });

  const searchCouponMutation = useMutation({
    mutationFn: searchCoupon,
  });

  const onSearchCoupon = useCallback(
    async (data: SearchByCouponForm) => {
      reset();

      try {
        const response = await searchCouponMutation.mutateAsync(
          Number(data.couponId)
        );
        setCouponInfo(response as ICoupon);
      } catch (error) {
        setCouponInfo(null);
      }
    },
    [reset, searchCouponMutation]
  );

  const setCouponStatus = () => {
    if (couponInfo !== null) {
      const updatedCouponInfo = {
        ...couponInfo,
        status: CouponStatusEnum.PAID,
      };
      setCouponInfo(updatedCouponInfo);
    }
  };

  const submitButtonContent = () => {
    return searchCouponMutation.isPending ? (
      <CircularProgress size={25} color="inherit" />
    ) : (
      'Buscar'
    );
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSearchCoupon)}>
        <Grid
          container
          direction="column"
          spacing={2}
          sx={{ marginBottom: theme.spacing(5) }}
        >
          <Grid item>
            <Typography
              variant="h5"
              color={theme.palette.text.primary}
              sx={{ fontWeight: 600 }}
            >
              Búsqueda
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="caption" color={theme.palette.text.secondary}>
              Ingresa el número de cupón de la factura
            </Typography>
          </Grid>
          <Grid item>
            <ControlledTextInput
              label="N° cupón"
              name="couponId"
              control={control}
              type="text"
              inputsProps={{
                sx: {
                  width: '100%',
                },
              }}
              errors={errors.couponId}
              isDirty={Boolean(dirtyFields?.couponId)}
            />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              type="submit"
              disabled={!isValid || searchCouponMutation.isPending}
              fullWidth
              sx={{
                padding: theme.spacing(1),
              }}
            >
              {submitButtonContent()}
            </Button>
          </Grid>
        </Grid>
      </form>
      {couponInfo !== null && (
        <PaymentCoupon
          couponInfo={couponInfo}
          setCouponStatus={setCouponStatus}
        />
      )}
    </>
  );
};

export default SearchView;
