import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { FC, useCallback } from 'react';

import theme from '../styles/theme';
import { ICoupon } from 'recaudo-movil-common';

interface IConfirmDialog {
  isOpen: boolean;
  handleClose: () => void;
  couponInfo: ICoupon;
  onConfirmPayCoupon: () => void;
}

const ConfirmDialog: FC<IConfirmDialog> = (props) => {
  const { isOpen, handleClose, couponInfo, onConfirmPayCoupon } = props;

  const onConfirm = useCallback(() => {
    handleClose();
    onConfirmPayCoupon();
  }, [handleClose, onConfirmPayCoupon]);

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle sx={{ padding: theme.spacing(3) }}>
        <Typography
          variant="h6"
          color={theme.palette.text.primary}
          sx={{ fontWeight: 600 }}
        >
          Confirmación de pago
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          ¿Está seguro que desea aplicar el pago por{' '}
          <b>${couponInfo.value.toLocaleString('de-DE')}</b> al cupón{' '}
          <b>#{couponInfo.couponId}</b>?
        </Typography>
      </DialogContent>
      <DialogActions
        sx={{
          paddingBottom: theme.spacing(3),
          paddingLeft: theme.spacing(3),
          paddingRight: theme.spacing(3),
        }}
      >
        <Button variant="contained" onClick={onConfirm}>
          Si
        </Button>
        <Button variant="outlined" onClick={handleClose}>
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
