import { Button, Card, Grid, Typography } from '@mui/material';
import { FC, useCallback, useState } from 'react';

import { CouponStatusEnum, ICoupon } from 'recaudo-movil-common';
import theme from '../styles/theme';
import ConfirmDialog from './ConfirmDialog';
import { useMutation } from '@tanstack/react-query';
import { payCoupon } from '../http/api/coupon';
import toast from 'react-hot-toast';
import LoadingPaymentView from './LoadingPaymentView';
import { numberWithDots } from 'recaudo-movil-common';

interface IPaymentCoupon {
  couponInfo: ICoupon;
  setCouponStatus: () => void;
}

const statusColor: Record<CouponStatusEnum, string> = {
  [CouponStatusEnum.PAID]: '#3EC178',
  [CouponStatusEnum.PENDING]: theme.palette.text.secondary,
};

const PaymentCoupon: FC<IPaymentCoupon> = ({ couponInfo, setCouponStatus }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onPayCoupon = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const payCouponMutation = useMutation({
    mutationFn: payCoupon,
  });

  const onConfirmPayCoupon = async () => {
    setIsLoading(true);
    try {
      await payCouponMutation.mutateAsync(couponInfo.couponId);
      setIsLoading(false);
      setCouponStatus();
      toast.success('¡Pago recibido!');
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Typography
            variant="h5"
            color={theme.palette.text.primary}
            sx={{ fontWeight: 600 }}
          >
            Cupón de pago
          </Typography>
        </Grid>
        <Grid item>
          <Card
            sx={{
              padding: theme.spacing(2),
            }}
          >
            <Grid container spacing={0.5}>
              <Grid item xs={6} justifyContent={'flex-start'}>
                <Typography
                  variant="overline"
                  color={statusColor[couponInfo.status as CouponStatusEnum]}
                  fontSize="0.9rem"
                  fontWeight="600"
                >
                  {couponInfo.status}
                </Typography>
              </Grid>
              <Grid item xs={6} justifyContent="flex-end" textAlign="right">
                <Typography
                  variant="overline"
                  color={theme.palette.text.secondary}
                  fontSize="0.9rem"
                  fontWeight="600"
                >
                  # {couponInfo.couponId}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                textAlign="center"
                sx={{
                  marginTop: theme.spacing(1),
                  marginBottom: theme.spacing(1),
                }}
              >
                <Typography
                  variant="h6"
                  color={theme.palette.text.primary}
                  sx={{ fontWeight: 600 }}
                >
                  {numberWithDots(couponInfo.value)}
                </Typography>
              </Grid>
              <Grid item xs={6} justifyContent="flex-start">
                <Typography
                  color={theme.palette.text.disabled}
                  fontSize="small"
                >
                  CONTRATO:
                </Typography>
              </Grid>
              <Grid item xs={6} justifyContent="flex-end" textAlign="right">
                <Typography
                  color={theme.palette.text.disabled}
                  fontSize="small"
                >
                  {couponInfo.contract}
                </Typography>
              </Grid>
              <Grid item xs={6} justifyContent="flex-start">
                <Typography
                  color={theme.palette.text.disabled}
                  fontSize="small"
                >
                  DIRECCIÓN:
                </Typography>
              </Grid>
              <Grid item xs={6} justifyContent="flex-end" textAlign="right">
                <Typography
                  color={theme.palette.text.disabled}
                  fontSize="small"
                >
                  {`${couponInfo.address} ${couponInfo.city}`}
                </Typography>
              </Grid>
              <Grid item xs={6} justifyContent="flex-start">
                <Typography
                  color={theme.palette.text.disabled}
                  fontSize="small"
                >
                  NOMBRE:
                </Typography>
              </Grid>
              <Grid item xs={6} justifyContent="flex-end" textAlign="right">
                <Typography
                  color={theme.palette.text.disabled}
                  fontSize="small"
                >
                  {couponInfo.name}
                </Typography>
              </Grid>
              {couponInfo.status === CouponStatusEnum.PENDING && (
                <Grid item xs={12} sx={{ marginTop: theme.spacing(1) }}>
                  <Button variant="contained" onClick={onPayCoupon} fullWidth>
                    PAGAR
                  </Button>
                </Grid>
              )}
            </Grid>
          </Card>
        </Grid>
      </Grid>
      <ConfirmDialog
        isOpen={isOpen}
        handleClose={handleClose}
        couponInfo={couponInfo}
        onConfirmPayCoupon={onConfirmPayCoupon}
      />
      <LoadingPaymentView isLoading={isLoading} />
    </>
  );
};

export default PaymentCoupon;
