import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1050b3',
    },
    text: {
      primary: '#7D7979',
      secondary: '#A5A1A1',
      disabled: '#8B8B8B',
    },
    background: {
      default: '#FFFFFF',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: '#1050b3',
          color: '#fff',
          '&:hover': {
            backgroundColor: '#159CCE',
            color: '#fff',
          },
          '&.Mui-disabled': {
            backgroundColor: '#a9c1e8',
            color: '#fff',
          },
        },
        outlined: {
          backgroundColor: '#fff',
          color: '#1050b3',
          border: 'solid #1050b3 1px',
          '&:hover': {
            backgroundColor: '#159CCE',
            color: '#fff',
          },
          '&.Mui-disabled': {
            color: '#8B8B8B',
            backgroundColor: '#F3F2F2',
            border: 'solid #8B8B8B 1px',
          },
        },
      },
    },
  },
});

export default theme;
