import { useState } from 'react';
import type { NextPage } from 'next';
import { Box, Container, Tab, ThemeProvider } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import PaidIcon from '@mui/icons-material/Paid';
import DescriptionIcon from '@mui/icons-material/Description';

import theme from '../styles/theme';
import SearchView from '../components/SearchView';
import ReportView from '../components/ReportView';

const Home: NextPage = () => {
  const [value, setValue] = useState<'payment' | 'report'>('payment');

  const handleChange = (
    event: React.SyntheticEvent,
    newValue: 'payment' | 'report'
  ) => {
    setValue(newValue);
  };

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="sm" sx={{ padding: 0 }}>
        <TabContext value={value}>
          <Box
            display="flex"
            justifyContent="center"
            sx={{
              marginBottom: theme.spacing(3),
            }}
          >
            <TabList
              onChange={handleChange}
              aria-label="tabs"
              sx={{ width: '100%' }}
            >
              <Tab
                icon={<PaidIcon />}
                label="Pagos"
                value="payment"
                sx={{ width: '50%' }}
              />
              <Tab
                icon={<DescriptionIcon />}
                label="Informes"
                value="report"
                sx={{ width: '50%' }}
              />
            </TabList>
          </Box>
          <TabPanel value="payment" sx={{ padding: 0 }}>
            <SearchView />
          </TabPanel>
          <TabPanel value="report" sx={{ padding: 0 }}>
            <ReportView />
          </TabPanel>
        </TabContext>
      </Container>
    </ThemeProvider>
  );
};

export default Home;
