const downloadBlob = (response: Blob) => {
  const url = window.URL.createObjectURL(new Blob([response]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', 'Reporte.pdf');
  document.body.appendChild(link);
  link.click();
};

export default downloadBlob;
