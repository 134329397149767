import { Backdrop, CircularProgress } from '@mui/material';
import { FC } from 'react';

interface ILoadingPaymentView {
  isLoading: boolean;
}

const LoadingPaymentView: FC<ILoadingPaymentView> = ({ isLoading }) => {
  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={isLoading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default LoadingPaymentView;
