import * as yup from 'yup';

export const GenerateReportSchema = yup.object({
  startDate: yup
    .date()
    .typeError('Seleccione una fecha')
    .required('Es requerido'),
  endDate: yup
    .date()
    .typeError('Seleccione una fecha')
    .required('Es requerido'),
});
